import { useParams } from "react-router-dom"
import { useEffect, useState } from 'react';
import { Card, ListGroup, Button, Container, Spinner, Row, Col } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';

import axios from "axios";
function Detail() {
    let { id } = useParams();
    let [ready, setReady] = useState(false);
    let [queryError, setQueryError] = useState(false);
    let [data, setData] = useState({});
    let [errorDisplay, setErrorDisplay] = useState(false)
    useEffect(() => {
        axios({
            method: 'post', url: 'https://sgt.muniomate.gob.pe/getInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ 'license': id })
        }).then((result) => {
            if (result.data) {
                let database = JSON.parse(result.data.data);
                setData(database)
                setReady(true)
            } else {
                setErrorDisplay(true)
                setTimeout(() => {
                    setQueryError(true);
                }, 30000);
                
            }
        }).catch((error) => {
            //console.log(error);
        })
    }, []);



    if (ready) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center',  }}>

                <Row >
                    <Col >
                        <Card className=' border-dark ' >
                            <Card.Header className='bg-dark text-white'>Respuesta de la base de datos </Card.Header>


                            <Card.Body >

                                <Container >
                                    <Row>

                                        <Col className="border col-12" >
                                            <ListGroup>
                                                <div> <strong>Cod Licencia: {data.licencia}  </strong> </div>
                                                <div> <strong>Categoria: {data.categoria} </strong></div>
                                                <div> <strong>Clase: {data.clase} </strong></div>
                                                <div> <strong>Nombre/s: </strong>  {data.nombres}</div>
                                                <div> <strong>Apellido/s: </strong>  {data.apellidos}</div>
                                                <div className="text-danger"> <strong>Restricciones: </strong>  {data.restricciones}</div>
                                                <div> <strong>DNI: </strong>  {data.dni}</div>
                                                <div> <strong>Domicilio: </strong>  {data.domicilio}</div>
                                                <div> <strong>Distrito: </strong>  {data.distrito}</div>
                                                <div> <strong>GrupoSangineo: </strong>  {data.grupoSangineo}</div>
                                                <div> <strong>Fecha de Emisión: </strong>  {data.fechaEmision}</div>
                                                <div className="text-danger"> <strong>Fecha de Renovación: </strong>  {data.fechaExpiracion}</div>
                                            </ListGroup>
                                        </Col>

                                        <Col className="border text-center" >
                                            <Container><img style={{ maxHeight: '170px', maxWidth: '170px', margin: '10%' }} src={`data:image/jpeg;base64,${data.image}`} /></Container>
                                        </Col>
                                    </Row>
                                </Container>

                                <Container style={{ marginTop: '5%' }}>
                                    <ListGroup variant="flush">
                                        <Button variant="success" to="/" as={Link}>
                                            Nueva Consulta/Salir
                                        </Button>

                                    </ListGroup>
                                </Container>


                            </Card.Body>

                        </Card>
                    </Col>
                </Row>


            </div>
        );
    }
    else {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30vh' }}>
                <Spinner animation="border" variant="success" role="status" />


                {errorDisplay &&
                <h2>La licencia que estas consultando no es valida</h2>
                }
                {
                    queryError &&
                    <Navigate replace to="/" />
                }
            </div>


        )
    }

}

export default Detail
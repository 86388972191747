import { useState } from 'react';
import { Card, ListGroup, Form, Button, Container } from 'react-bootstrap';
import { Link  } from 'react-router-dom';

function Consulta() {
    let [value, setValue] = useState('')
    function Change(event) {
        setValue(event.target.value);
    }

    return (
        <div>


            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30vh' }}>

                <Card className='card border-dark' >
                    <Card.Header className='bg-dark text-white'>Ingresa el Id de licencia</Card.Header>
                    <Card.Body >

                        <Form.Control type="text" maxLength={15}  onChange={Change}  placeholder="E99999999" />
                        <Container style={{ marginTop: '20%' }}>
                            <ListGroup variant="flush">

                                <Button variant="danger" to={'detail/' + value} as={Link} >
                                    
                                    Consultar
                                </Button>

                            </ListGroup>
                        </Container>


                    </Card.Body>

                </Card>

            </div>
        </div>
            )
}

            export default Consulta;
import { useState } from 'react';
import { Card, ListGroup, Form, Button, Container } from 'react-bootstrap';
function Login() {

    let [errorLogin, setErrorLogin] = useState(false);


    return (
        <>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30vh' }}>

                <Card className='card border-dark' >
                    <Card.Header className='bg-dark text-white'>Credenciales requeridas </Card.Header>


                    <Card.Body >

                        <Form.Control type="text" placeholder="Administrador" />
                        <Form.Control type="password" style={{ marginTop: '10%' }} placeholder="*****" />
                        <Container style={{ marginTop: '20%' }}>
                            <ListGroup variant="flush">

                                <Button variant="danger" onClick={() => setErrorLogin(true)}>
                                    Log-In
                                </Button>

                            </ListGroup>
                        </Container>

                        {
                            errorLogin &&
                            <Card.Text className='text-center  text-danger' style={{ marginTop: '10%' }}>
                                Usuario o contraseña <br /> invalido
                            </Card.Text>
                        }
                    </Card.Body>

                </Card>

            </div>
        </>


    )

}

export default Login;
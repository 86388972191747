import { useState } from 'react';
import { Card, Form, Button, Container, Col, Row } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom'
import React from 'react';
import QRCode from 'react-qr-code';
import axios from "axios";
import { Buffer } from 'buffer';
function Admin() {

    let [qrValue, setQrValue] = useState('');
    let [qrImage, setQrImage] = useState('');
    let [licencia, setLicencia] = useState('');
    let [clase, setClase] = useState('');
    let [categoria, setCategoria] = useState('');
    let [nombres, setNombres] = useState('');
    let [apellidos, setApellidos] = useState('');
    let [dni, setDni] = useState(0);
    let [fechaEmision, setfechaEmision] = useState('');
    let [fechaExpiracion, setFechaRenovacion] = useState('');
    let [domicilio, setDomicilio] = useState('');
    let [distrito, setDistrito] = useState('');
    let [grupoSangineo, setGrupoSangineo] = useState('');
    let [restricciones, setRestricciones] = useState('');
    let [Img, setImg] = useState('');
    let [error, setError] = useState(false);
    let [success, setSuccess] = useState(false);

    function onImageCownload() {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.download = "QRCode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };


    async function handlerFile(event) {

        let dataRaw = await event.target.files[0].arrayBuffer();


        let buffer = Buffer.from(dataRaw);

        setImg(buffer.toString('base64'));

    }

    function register() {

        if (error) {
            setError(false);
        }

        let currentData = {
            image: Img,
            licencia,
            clase,
            categoria,
            nombres,
            apellidos,
            dni,
            fechaEmision,
            fechaExpiracion,
            domicilio,
            distrito,
            grupoSangineo,
            restricciones
        }
        let errorFound = false;
        Object.keys(currentData).forEach((currentKey, keyCurrent) => {

            if (!currentData[currentKey]) {
                setError(true);
                errorFound = true;
            }




            if (Object.keys(currentData).length - 1 === keyCurrent) {


                if (!errorFound) {
                    axios({
                        method: 'post', url: 'https://sgt.muniomate.gob.pe/register',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: JSON.stringify(currentData)
                    }).then((result) => {
                        setSuccess(true)
                        setTimeout(() => {
                            setSuccess(false)
                        }, 6000);
                        if (result.data) {

                        } else {

                        }
                    }).catch((error) => {
                        //console.log(error);
                    })
                } else {
                    setTimeout(() => {
                        setError(false);
                    }, 6000);
                }
            }
        });




    }

    function renderQrImg() {
        let svg = document.getElementById("QRCode");
        let svgData = new XMLSerializer().serializeToString(svg);
        setQrImage(btoa(svgData));
    }


    return (

        <div style={{}}>
            <Row className="justify-content-center">

                <Col >

                    <Card>

                        <Card.Header className="bg-primary text-center text-light"> Registrar licencia</Card.Header>


                        <Card.Body className='text-black'>

                            <Form id='registerForm'>
                                <Row>
                                    <Form.Group as={Col} controlId="formGridLicencia">
                                        <Form.Label>Numero de Licencia</Form.Label>
                                        <Form.Control required type="text" placeholder="999999999" onChange={(event) => {
                                            setLicencia(event.target.value);


                                            setQrValue(`https://sgt.muniomate.gob.pe/detail/${event.target.value}`)
                                            setQrImage('');


                                        }} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridLicClass">
                                        <Form.Label>Clase</Form.Label>
                                        <Form.Control required type="text" placeholder="AAA" onChange={(event) => {
                                            setClase(event.target.value)
                                        }} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridLicCategoria">
                                        <Form.Label>Categoría</Form.Label>
                                        <Form.Control required type="text" placeholder="AAA" onChange={(event) => {
                                            setCategoria(event.target.value);
                                        }} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridDNI">
                                        <Form.Label>DNI</Form.Label>
                                        <Form.Control required type="number" placeholder="4444444" onChange={(event) => {
                                            setDni(event.target.value);
                                        }} />
                                    </Form.Group>

                                </Row>

                                <Row>
                                    <Form.Group as={Col} controlId="formGridNombre">
                                        <Form.Label>Nombres</Form.Label>
                                        <Form.Control required placeholder="Anastacio" onChange={(event) => {
                                            setNombres(event.target.value)
                                        }} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridApellidos">
                                        <Form.Label>Apellidos</Form.Label>
                                        <Form.Control required placeholder="Apaza" onChange={(event) => {
                                            setApellidos(event.target.value)
                                        }} />
                                    </Form.Group>

                                    <Form.Group as={Col} lg='2' controlId="formGridGrupoSangineo">
                                        <Form.Label>Grupo Sanguíneo</Form.Label>
                                        <Form.Control required placeholder="A+" onChange={(event) => {
                                            setGrupoSangineo(event.target.value)
                                        }} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridRestricciones">
                                        <Form.Label>Restricciones</Form.Label>
                                        <Form.Control required onChange={(event) => {
                                            setRestricciones(event.target.value)
                                        }} />
                                    </Form.Group>

                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="formGridDistrito">
                                        <Form.Label>Distrito</Form.Label>
                                        <Form.Control required onChange={(event) => {
                                            setDistrito(event.target.value);
                                        }} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridDomicilio">
                                        <Form.Label>Domicilio</Form.Label>
                                        <Form.Control required onChange={(event) => {
                                            setDomicilio(event.target.value);
                                        }} />


                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridFechaEmision">
                                        <Form.Label>Fecha de Emisión</Form.Label>
                                        <Form.Control type='date' required onChange={(event) => {
                                            setfechaEmision(event.target.value);

                                        }} />


                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridDomicilio">
                                        <Form.Label>Fecha de Renovación</Form.Label>
                                        <Form.Control type='date' required onChange={(event) => {
                                            setFechaRenovacion(event.target.value);
                                        }} />


                                    </Form.Group>



                                </Row>


                                <Row style={{ marginTop: '5%' }}>

                                    <Col className="text-center border bg-light text-dark" >
                                        <Row>
                                            <Col>
                                                <QRCode style={{}} id='QRCode' value={qrValue} />
                                                <br /><a>Preview QR</a>
                                            </Col>
                                            <Col>
                                                <Container style={{ padding: '4%' }}>

                                                    {qrImage &&
                                                        <img style={{ maxHeight: '170px', maxWidth: '170px' }} src={`data:image/svg+xml;base64,${qrImage}`} />


                                                    }
                                                </Container>
                                            </Col>

                                        </Row>

                                    </Col>

                                    <Col className='border' >
                                        <Form.Group controlId="formGridApellidos">
                                            <Form.Label>Foto Carnet</Form.Label>
                                            <Form.Control type='file' onChange={handlerFile} />
                                        </Form.Group >
                                        <div className='text-center border' style={{ marginTop: '5%' }}>

                                            {Img &&
                                                <img style={{ maxHeight: '170px', maxWidth: '170px' }} src={`data:image/jpeg;base64,${Img}`} />
                                            }

                                        </div>


                                    </Col>


                                </Row>

                                <Row style={{ marginTop: '5%' }}>
                                    <Col>
                                        <Row>

                                            <Col>
                                                <Button variant="success" onClick={onImageCownload}>
                                                    Descargar Codigo-QR
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button variant="warning" onClick={renderQrImg}>
                                                    Renderizar Codigo-QR
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col>
                                        <Col>

                                            <Button variant="danger" onClick={register} >
                                                Registrar
                                            </Button>


                                        </Col>

                                    </Col>

                                    <Col>
                                        <Button variant="primary" onClick={() => {
                                             document.getElementById("registerForm").reset();
                                             setImg('');
                                        }} >
                                            Nuevo registro
                                        </Button>
                                    </Col>

                                    <Col>
                                        <Button variant="primary" as={Link} to='/' >
                                            Consultas
                                        </Button>
                                    </Col>

                                    <Col>
                                        <Button variant="primary" as={Link} to='/' >
                                            Salir
                                        </Button>
                                    </Col>


                                </Row>

                                {error &&

                                    <Row className='text-center text-danger'> <Col> <h4>Fallo el registro, por favor verifica que todos los campos esten completos</h4> </Col> </Row>


                                }

                                {success &&

                                    <Row className='text-center text-success'> <Col> <h4>El registro se ha completado, los datos del formulario no fueron eliminados</h4> </Col> </Row>


                                }
                            </Form>

                        </Card.Body>

                    </Card>

                </Col>

            </Row>





        </div>




    );

}

export default Admin;
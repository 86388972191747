import {Route, Routes, } from 'react-router-dom'
import Consulta from './pages/consulta';
import Login from './pages/login';
import Admin from './pages/admin';
import Detail from './pages/detail';
function App() {
  return (

    <div>



     <Routes>
       <Route path='/' element = { < Consulta />}>
         
          
       </Route>
       <Route path='login' element= {<Login/>}/>
       <Route path='admin' element= {<Admin/>}/>
       
       <Route path='detail/:id' element={<Detail/>}>
       </Route>


       <Route path='*' element = {<Consulta/>} />
     </Routes>
      
    </div>


  );
}

export default App;
